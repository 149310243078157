import ServicesTemplate from "../../components/ServicesTemplate";
import { ServicesProps } from "../../service";

const config: ServicesProps = {
  title: "Web Services",
  sectionHeader: "Web Services that make life easy",
  sectionContent:
    "Our wide ranging Web Service portfolio takes the stress out of the web. From website development to domain hosting and management. goSystem Web Services combines affordability, years of experience and industry-leading customer service to provide not just the most wide ranging portfolio but the best customer experience possible.",
  splitTag: "Always Online",
  splitHeading: "Guaranteed uptime and industry-leading support",
  splitContent:
    "We pride ourselves on our industry-leading support, with named support advisors, 24/7 response, and an average response time of just 3 minutes. We guarantee that you will never be left without the you need to succeed. Our friendly team are always there when you need us.",
  splitImg: "DataCenter",
  servicesHeading: "Our Web Services",
  servicesCards: [
    {
      title: "DNS Services",
      body: "goSystem DNS services are easy to use, reliable and secure. We have DNS servers over 40 countries worldwide, peering with all the major DNS providers, ensuring propagation is rapid, and DNS requests are guaranteed the most current data.",
      img: "CLOUD",
    },
    {
      title: "SSL Management",
      body: "We take the hassle out of SSL/TLS certificate management and deployment. Our low cost and free SSL certificates guarantee security and reliability. Our SSL management service ensures your certificates are always valid and never expire, with auto-renew and auto-deployment.",
      img: "WORLD_WIDE_WEB",
    },
    {
      title: "Domain Management & Registration",
      body: "goSystem is a domain registrar providing low-cost domains from a wide range of providers, from .com to .xyz. You can find every TLD you can think of with goSystem. Our management console is simple to use and extremely powerful.",
      img: "CONNECT_DEVELOP",
    },
    {
      title: "Website Design & Development",
      body: "Our responsive, beautiful and bespoke websites are proven to drive engagement, increase sales and leave a lasting impression. It is now more important than ever to have a great looking website, and goSystem are able to provide just that.",
      img: "WEB_IMG",
    },
    {
      title: "Web-App Development",
      body: "goSystem provides affordable cloud hosting solutions to ensure your website, API, or web app is always accessible, fast and secure. With infrastructure in 7 data centres, reliability is guaranteed.",
      img: "SMARTPHONE_HEART",
    },
    {
      title: "Domain Management & Registration",
      body: "goSystem provides fully managed Microsoft 365 solutions, ensuring your employees have the tools they need to maximise productivity and communication. From single users to thousands of managed accounts.",
      img: "CONNECT_DEVELOP",
    },
  ],
  callToAction: "Discover what goSystem can do for you",
};

export default () => ServicesTemplate(config);
