import { PageContainer } from "../PageContainer";
import { PageSection } from "../PageSection";
import React, { ReactNode } from "react";

const SectionText = ({
  heading,
  children,
}: {
  heading?: string;
  children: ReactNode;
}) => (
  <PageSection>
    <PageContainer>
      <div className="grid grid-cols-1 gap-10 py-24 xl:grid-cols-3">
        <div className="text-5xl font-bold">{heading}</div>
        <div className="xl:col-span-2 text-2xl">{children}</div>
      </div>
    </PageContainer>
  </PageSection>
);

export { SectionText };
