import { StaticImage } from "gatsby-plugin-image";
import React from "react";

const AWSImage = () => (
  <StaticImage
    className="w-full h-full"
    src="../../images/gcp-x-azure-x-aws.png"
    alt="aws promo"
  />
);
const DataCentreImage = () => (
  <StaticImage
    className="w-full h-full"
    src="../../images/data-center.jpg"
    alt="data center"
  />
);
const CloudImage = () => (
  <StaticImage
    className="w-full h-full"
    src="../../images/cloud.png"
    alt="cloud promo"
  />
);
const CloudVPSImage = () => (
  <StaticImage
    className="w-full h-full"
    src="../../images/cloud-VPS.png"
    alt="cloud vps"
  />
);
const AntennaImage = () => (
  <StaticImage
    className="w-full h-full"
    src="../../images/antenna.png"
    alt="antenna promo image"
  />
);
const MicrosoftImage = () => (
  <StaticImage
    className="w-full h-full"
    src="../../images/Grafik-Microsoft-365.png"
    alt="microsoft services promo"
  />
);
const LondonSkylineImage = () => (
  <StaticImage
    className="w-full h-full"
    src="../../images/Shared_web-scaled.png"
    alt="promo image"
  />
);

export const IMAGES = {
  AWS: <AWSImage />,
  DataCenter: <DataCentreImage />,
  Cloud: <CloudImage />,
  CloudVPS: <CloudVPSImage />,
  Antenna: <AntennaImage />,
  Microsoft: <MicrosoftImage />,
  ShardWebScaled: <LondonSkylineImage />,
  LondonSkylineImage: <LondonSkylineImage />,
};

export {
  AWSImage,
  DataCentreImage,
  CloudImage,
  CloudVPSImage,
  AntennaImage,
  MicrosoftImage,
  LondonSkylineImage,
};
