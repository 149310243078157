import { PageLayout } from "../../components/PageLayout";
import { PageParticleSectionJumbotron } from "../../components/PageParticleSectionJumbotron";
import { ServicesProps } from "../../service";
import { CardContainer } from "../CardContainer";
import { PageColourfulSection } from "../PageColourfulSection";
import { PageContainer } from "../PageContainer";
import { PageSection } from "../PageSection";
import { PageSectionContent } from "../PageSectionContent";
import { PageSectionSplit } from "../PageSectionSplit";
import { SectionCallToAction } from "../SectionCallToAction";
import { SectionPartners } from "../SectionPartners";
import { SectionText } from "../SectionText";
import { ICONS } from "../SiteIcon/index";
import { IMAGES } from "../SiteImage";
import { SiteSEO } from "../SiteSEO";
import React from "react";
import Fade from "react-reveal/Fade";

const ServicesTemplate: React.FC<ServicesProps> = (props) => {
  return (
    <PageLayout transparentHeader>
      <SiteSEO title={props.title} />
      <PageParticleSectionJumbotron transparentHeader>
        {props.title}
      </PageParticleSectionJumbotron>

      <SectionText heading={props.sectionHeader}>
        {props.sectionContent}
      </SectionText>

      {props.splitTag &&
        props.splitHeading &&
        props.splitContent &&
        props.splitImg && (
          <PageSectionSplit
            left={
              <PageColourfulSection className="h-full flex items-center">
                <div className="py-36 w-full h-full">
                  <PageContainer>
                    <PageSectionContent
                      className="text-white"
                      tagClass="text-white"
                      tag={props.splitTag}
                      heading={props.splitHeading}
                      content={props.splitContent}
                    />
                  </PageContainer>
                </div>
              </PageColourfulSection>
            }
            right={IMAGES[props.splitImg]}
          />
        )}

      {props.servicesHeading && props.servicesCards && (
        <PageSection>
          <PageContainer>
            <h2 className="text-5xl my-8 font-bold">{props.servicesHeading}</h2>
            <CardContainer>
              {props.servicesCards
                .map((card, index) => (
                  <div
                    key={index}
                    className="p-4 gap-2 md:py-12 md:px-10 shadow-2xl flex flex-col md:gap-3"
                  >
                    {ICONS[card.img]}
                    <h3 className="text-xl font-bold">{card.title}</h3>
                    <p>{card.body}</p>
                  </div>
                ))
                .map((e: React.ReactNode, i: number) => (
                  <Fade key={i} delay={i * 150}>
                    {e}
                  </Fade>
                ))}
            </CardContainer>
          </PageContainer>
        </PageSection>
      )}

      <SectionPartners />
      <SectionCallToAction>{props.callToAction}</SectionCallToAction>
    </PageLayout>
  );
};

export default ServicesTemplate;
